import {
	GET_ALL_BUILDINGS,
	ADD_BUILDING,
	DELETE_BUILDING,
	EDIT_BUILDING,
	CISCO_POWER
} from '../constants'


const initialState = {
	isLoading: false,
	isError: false,
	allBuildings: {},
	ciscoResponse: {}
};

const buildings = (state = initialState, action) => {

	switch(action.type) {
		case `${GET_ALL_BUILDINGS}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${GET_ALL_BUILDINGS}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				allBuildings: action.payload
			};
		case `${GET_ALL_BUILDINGS}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${ADD_BUILDING}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${ADD_BUILDING}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${ADD_BUILDING}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${DELETE_BUILDING}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${DELETE_BUILDING}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${DELETE_BUILDING}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${EDIT_BUILDING}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${EDIT_BUILDING}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${EDIT_BUILDING}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${CISCO_POWER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${CISCO_POWER}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				ciscoResponse: action.payload
			};
		case `${CISCO_POWER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		default:
			return state;
	}

}

export default buildings;
