import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
import rootReducer from "../reducers/index";


var middleware = [
	thunk,
	createPromise({
		promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR']
	})
];

if ( process.env.NODE_ENV === 'development' ) {
	const { logger } = require('redux-logger');

	middleware.push(logger);
}

const store = createStore(
	rootReducer,
	applyMiddleware( ...middleware )
);

export default store;
