// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// API
// const BASEURL = '//localhost:8888/php-crud-api/api.php';
// export const BASEURL = '//localhost/php-crud-api/api.php';
export const BASEURL = '//portal.johnlander.me/php-crud-api/api.php';

// Buildings
export const GET_ALL_BUILDINGS = "GET_ALL_BUILDINGS";
export const ADD_BUILDING = "ADD_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const EDIT_BUILDING = "EDIT_BUILDING";
export const CISCO_POWER = "CISCO_POWER";

// Providers
export const GET_ALL_PROVIDERS = "GET_ALL_PROVIDERS";
export const ADD_PROVIDER = "ADD_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const EDIT_PROVIDER = "EDIT_PROVIDER";

// Customers
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const GET_ALL_EXTENSIONS = "GET_ALL_EXTENSIONS";
export const GET_ALL_SERVICE_PLANS = "GET_ALL_SERVICE_PLANS";
export const DELETE_EXTENSION = "DELETE_EXTENSION";
export const DELETE_SERVICE_PLAN = "DELETE_SERVICE_PLAN";