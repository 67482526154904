import axios from 'axios';
import {
	BASEURL,
	GET_ALL_PROVIDERS,
	ADD_PROVIDER,
	DELETE_PROVIDER,
	EDIT_PROVIDER
} from '../constants'


// const api = axios.create({
// 	headers: {
// 		Authorization: "Authorization"
// 	}
// });

export function getAllProviders() {
	return (dispatch, getState) => {
		return dispatch({
			type: GET_ALL_PROVIDERS,
			payload: axios.get(BASEURL + '/records/providers')
		})
		.then(response => {
			// console.log("getAllBuildings response is: ", response);
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function addProvider(params) {
	console.log('the params are: ', params);
	console.log('the type is: ', (typeof params));
	return (dispatch, getState) => {
		return dispatch({
			type: ADD_PROVIDER,
			payload: axios.post(BASEURL + '/records/providers', params),
			body: params
		})
		.then(response => {
			console.log("response is: ", response);
			// this.getAllProviders();
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function deleteProvider(id) {
	console.log('the id is: ', id);
	console.log('the type is: ', (typeof id));
	return (dispatch, getState) => {
		return dispatch({
			type: DELETE_PROVIDER,
			payload: axios.delete(BASEURL + '/records/providers/' + id)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function editProvider(params, id) {
	console.log('the params are: ', params);
	// console.log('the type is: ', (typeof params));
	return (dispatch, getState) => {
		return dispatch({
			type: EDIT_PROVIDER,
			payload: axios.put(BASEURL + '/records/providers/' + id, params)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}
