import {
	GET_ALL_CUSTOMERS,
	ADD_CUSTOMER,
	DELETE_CUSTOMER,
	EDIT_CUSTOMER,
	GET_ALL_EXTENSIONS,
	GET_ALL_SERVICE_PLANS,
	DELETE_EXTENSION,
	DELETE_SERVICE_PLAN
} from '../constants'


const initialState = {
	isLoading: false,
	isError: false,
	allCustomers: {},
	allExtensions: {},
	allServicePlans: {}
};

const customers = (state = initialState, action) => {

	switch(action.type) {
		case `${GET_ALL_CUSTOMERS}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${GET_ALL_CUSTOMERS}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				allCustomers: action.payload
			};
		case `${GET_ALL_CUSTOMERS}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Add customer
		case `${ADD_CUSTOMER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${ADD_CUSTOMER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${ADD_CUSTOMER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Delete customer
		case `${DELETE_CUSTOMER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${DELETE_CUSTOMER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${DELETE_CUSTOMER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Edit customer
		case `${EDIT_CUSTOMER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${EDIT_CUSTOMER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${EDIT_CUSTOMER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Get extensions
		case `${GET_ALL_EXTENSIONS}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${GET_ALL_EXTENSIONS}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				allExtensions: action.payload
			};
		case `${GET_ALL_EXTENSIONS}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Get service plans
		case `${GET_ALL_SERVICE_PLANS}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${GET_ALL_SERVICE_PLANS}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				allServicePlans: action.payload
			};
		case `${GET_ALL_SERVICE_PLANS}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Delete extension
		case `${DELETE_EXTENSION}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${DELETE_EXTENSION}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${DELETE_EXTENSION}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};
		// Delete service plan
		case `${DELETE_SERVICE_PLAN}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${DELETE_SERVICE_PLAN}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${DELETE_SERVICE_PLAN}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		default:
			return state;
	}

}

export default customers;
