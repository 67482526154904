import {
	GET_ALL_PROVIDERS,
	ADD_PROVIDER,
	DELETE_PROVIDER,
	EDIT_PROVIDER
} from '../constants'


const initialState = {
	isLoading: false,
	isError: false,
	allProviders: {}
};

const providers = (state = initialState, action) => {

	switch(action.type) {
		case `${GET_ALL_PROVIDERS}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${GET_ALL_PROVIDERS}_SUCCESS`:
			return {
				...state,
				isLoading: false,
				allProviders: action.payload
			};
		case `${GET_ALL_PROVIDERS}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${ADD_PROVIDER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${ADD_PROVIDER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${ADD_PROVIDER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${DELETE_PROVIDER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${DELETE_PROVIDER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${DELETE_PROVIDER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		case `${EDIT_PROVIDER}_LOADING`:
			return {
				...state,
				isLoading: true
			};
		case `${EDIT_PROVIDER}_SUCCESS`:
			return {
				...state,
				isLoading: false
			};
		case `${EDIT_PROVIDER}_ERROR`:
			return {
				...state,
				isLoading: false,
				isError: true
			};

		default:
			return state;
	}

}

export default providers;
