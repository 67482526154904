import { bindActionCreators } from 'redux';
import { toggleSidebar } from './sidebarActions';

import {
	getAllBuildings,
    addBuilding,
    deleteBuilding,
    editBuilding,
    ciscoPowerSwitch
} from './buildingActions';

import {
    getAllProviders,
    addProvider,
    deleteProvider,
    editProvider
} from './providerActions';

import {
    getAllCustomers,
    addCustomer,
    deleteCustomer,
    editCustomer,
    deleteExtension,
    deleteServicePlan
} from './customerActions';


const actions = (dispatch) => {
    return bindActionCreators({
    	toggleSidebar,
    	getAllBuildings,
        addBuilding,
        deleteBuilding,
        editBuilding,
        ciscoPowerSwitch,
        getAllProviders,
        addProvider,
        deleteProvider,
        editProvider,
        getAllCustomers,
        addCustomer,
        deleteCustomer,
        editCustomer,
        deleteExtension,
        deleteServicePlan
    }, dispatch);
}

export default actions;
