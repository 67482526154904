import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import mapDispatchToProps from '../redux/actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import {
  Row,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardLink,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";

import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  PieChart,
  Settings,
  User,
  UserPlus
} from "react-feather";

import avatar1 from "../assets/img/avatars/avatar.jpg";


const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

class NavbarComponent extends Component {
    _isMounted = false;

    constructor(props) {
      super(props);

      this.searchBar = React.createRef();

      this.state = {
          searchActive: false,
          searchTerm: '',
          buildingsCollection: [],
          displayedBuildings: [],
          providerCollection: [],
          displayedProviders: [],
          customerCollection: [],
          displayedCustomers: []
      };

      this.onInputChange = this.onInputChange.bind(this);
      this.renderBuildings = this.renderBuildings.bind(this);
      this.renderProviders = this.renderProviders.bind(this);
      this.renderCustomers = this.renderCustomers.bind(this);
      this.clearSearchBar = this.clearSearchBar.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        // Get All Buildings
        this.props.getAllBuildings().then((response) => {
            // console.log('getAllBuildings promise:');
            // console.log(this.props);

          if (typeof this.props.buildings.allBuildings.data !== 'undefined') {

            let buildingsData = _.reduce(this.props.buildings.allBuildings.data.records, function(result, value, key) {
                (result[value] || (result[value] = [])).push([value.id, value.building_overview_name]);
                return result;
            }, {});

            if (this._isMounted) {
              this.setState({
                buildingsCollection: buildingsData[Object.keys(buildingsData)[0]],
                displayedBuildings: buildingsData[Object.keys(buildingsData)[0]]
              });
            }

          }
        });

        // Get All Providers
        this.props.getAllProviders().then((response) => {
            // console.log('getAllBuildings promise:');
            // console.log(this.props);

          if (typeof this.props.providers.allProviders.data !== 'undefined') {

            let providersData = _.reduce(this.props.providers.allProviders.data.records, function(result, value, key) {
                (result[value] || (result[value] = [])).push([value.id, value.provider_name]);
                return result;
            }, {});

            if (this._isMounted) {
              this.setState({
                providerCollection: providersData[Object.keys(providersData)[0]],
                displayedProviders: providersData[Object.keys(providersData)[0]]
              });
            }
            
          }
        });

        // Get All Customers
        this.props.getAllCustomers().then((response) => {
            // console.log('getAllBuildings promise:');
            // console.log(this.props);

          if (typeof this.props.customers.allCustomers.data !== 'undefined') {

            let customersData = _.reduce(this.props.customers.allCustomers.data.records, function(result, value, key) {
                (result[value] || (result[value] = [])).push([value.id, value.customer_first_name]);
                return result;
            }, {});

            if (this._isMounted) {
              this.setState({
                customerCollection: customersData[Object.keys(customersData)[0]],
                displayedCustomers: customersData[Object.keys(customersData)[0]]
              });
            }
            
          }
        });
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    onInputChange(event) {
      let newlyDisplayedBuildings = _.filter( this.state.buildingsCollection, function(value) {
        // console.log(value);
              return ( (value[1].toLowerCase()).includes(event.target.value.toLowerCase()) ? value : false );
          }
      );

      let newlyDisplayedProviders = _.filter( this.state.providerCollection, function(value) {
              return ( (value[1].toLowerCase()).includes(event.target.value.toLowerCase()) ? value : false );
          }
      );

      let newlyDisplayedCustomers = _.filter( this.state.customerCollection, function(value) {
              return ( (value[1].toLowerCase()).includes(event.target.value.toLowerCase()) ? value : false );
          }
      );

      this.setState({
          ...this.state,
          searchActive: true,
          searchTerm: event.target.value,
          displayedBuildings: newlyDisplayedBuildings,
          displayedProviders: newlyDisplayedProviders,
          displayedCustomers: newlyDisplayedCustomers
      });

      if (event.target.value === '') {
          this.setState({
              searchActive: false
          });
      }

      // console.log('state is: ', this.state);
    }

    clearSearchBar(e) {
        this.setState({
            searchActive: false,
            searchTerm: ''
        });

        return false;
    }

    renderBuildings() {
      if (typeof this.state.displayedBuildings !== 'undefined') {
        return this.state.displayedBuildings.map((building, item) => {
            // console.log(building);
          return (
            <div key={item}><Link to={"/buildings/" + building[0]} onClick={(e) => this.clearSearchBar(e)}>{building[1]}</Link></div>
          );
        });
      }
    }

    renderProviders() {
      if (typeof this.state.displayedProviders !== 'undefined') {
        return this.state.displayedProviders.map((provider, item) => {
          return (
            <div key={item}><Link to={"/providers/" + provider[0]} onClick={(e) => this.clearSearchBar(e)}>{provider[1]}</Link></div>
          );
        });
      }
    }

    renderCustomers() {
      if (typeof this.state.displayedCustomers !== 'undefined') {
        return this.state.displayedCustomers.map((customer, item) => {
          return (
            <div key={item}><Link to={"/customers/" + customer[0]} onClick={(e) => this.clearSearchBar(e)}>{customer[1]}</Link></div>
          );
        });
      }
    }

  render() {
    return (
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            this.props.toggleSidebar();
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Form inline>
          <Input
            type="text"
            placeholder="Search..."
            aria-label="Search"
            className="form-control-no-border mr-sm-2"
            onChange={this.onInputChange}
            ref={this.searchBar}
            value={this.state.searchTerm}
          />
        </Form>

        {/*<Col lg="6">
            <FormGroup className="mb-0">
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={options}
                    isSearchable
                    placeholder="Search..."
                    onChange={this.onInputChange}
                />
            </FormGroup>
        </Col>*/}

        <Collapse navbar>
          <Nav className="ml-auto" navbar>

            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <img
                    src={avatar1}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt="Adam Vierra"
                  />
                  <span className="text-dark">Adam Vierra</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem>
                  <User size={18} className="align-middle mr-2" />
                  Account
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Help</DropdownItem>
                <DropdownItem>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>

        <Card className={ this.state.searchActive ? "search-results position-absolute" : "search-results position-absolute collapse" }>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                    Search Results
                </CardTitle>
            </CardHeader>

            <CardBody>
                <CardTitle tag="h5" className="mb-0">
                    Buildings
                </CardTitle>
                <div className="mb-3">
                    {this.renderBuildings()}
                </div>

                <CardTitle tag="h5" className="mb-0">
                    Providers
                </CardTitle>
                <div className="mb-3">
                    {this.renderProviders()}
                </div>

                <CardTitle tag="h5" className="mb-0">
                    Customers
                </CardTitle>
                <div className="mb-3">
                    {this.renderCustomers()}
                </div>
            </CardBody>
        </Card>
      </Navbar>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
    return {
      buildings: state.buildings,
      providers: state.providers,
      customers: state.customers
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarComponent);
