import axios from 'axios';
import {
	BASEURL,
	GET_ALL_CUSTOMERS,
	ADD_CUSTOMER,
	DELETE_CUSTOMER,
	EDIT_CUSTOMER,
	GET_ALL_EXTENSIONS,
	GET_ALL_SERVICE_PLANS,
	DELETE_EXTENSION,
	DELETE_SERVICE_PLAN
} from '../constants'


// const api = axios.create({
// 	headers: {
// 		Authorization: "Authorization"
// 	}
// });

export function getAllCustomers() {
	return (dispatch, getState) => {
		return dispatch({
			type: GET_ALL_CUSTOMERS,
			payload: axios.get(BASEURL + '/records/customers')
		})
		.then(response => {
			// console.log("getAllCustomers response is: ", response);
			dispatch({
				type: GET_ALL_EXTENSIONS,
				payload: axios.get(BASEURL + '/records/customer_voice_extension')
			})
			.then(response => {
				// console.log("Get extensions response is: ", response);
				dispatch({
					type: GET_ALL_SERVICE_PLANS,
					payload: axios.get(BASEURL + '/records/customer_microsoft_service_plan')
				})
				.then(response => {
					// console.log("Get service plans response is: ", response);
				})
				.catch((error) => {
					if (error instanceof Error) {
						console.log(error);
					}
				});
			})
			.catch((error) => {
				if (error instanceof Error) {
					console.log(error);
				}
			});
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function addCustomer(params) {
	console.log('the params are: ', params);
	console.log('the type is: ', (typeof params));
// return false;
	return (dispatch, getState) => {
		return dispatch({
			type: ADD_CUSTOMER,
			payload: axios.post(BASEURL + '/records/customers', params.main),
			body: params
		})
		.then(response => {
			console.log("response is: ", response);
			const customerId = response.value.data;

			// if both, if voice only, if microsoft only, else none
			if ( Object.keys(params.customer_voice_extension).length !== 0 || Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {

				const voiceValues = Object.values(params.customer_voice_extension);
				const msValues = Object.values(params.customer_microsoft_service_plan);
				let voiceIterations = voiceValues.length;
				let msIterations = msValues.length;

				if ( Object.keys(params.customer_voice_extension).length !== 0 && Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
					voiceValues.forEach(function(value, key) {
						const voiceParams = {
							customer_id: customerId,
							extension: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_voice_extension', voiceParams)
						})
						.then(response => {
							console.log('voice extension was dispatched');

							if (!--voiceIterations) {
								console.log("This is the last voice iteration...");

								if ( Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
									msValues.forEach(function(value, key) {
										const msParams = {
											customer_id: customerId,
											service_plan: value
										};

							        	dispatch({
											type: ADD_CUSTOMER,
											payload: axios.post(BASEURL + '/records/customer_microsoft_service_plan', msParams)
										})
										.then(response => {
											console.log('microsoft service plan was dispatched');

											if (!--msIterations) {
												console.log("This is the last ms iteration for both...");
												window.location.reload();
											}
										})
										.catch((error) => {
											if (error instanceof Error) {
												console.log(error);
											}
										});
							        });
								} else {
									window.location.reload();
								}
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				} else if ( Object.keys(params.customer_voice_extension).length !== 0 && Object.keys(params.customer_microsoft_service_plan).length == 0 ) {
					voiceValues.forEach(function(value, key) {
						const voiceParams = {
							customer_id: customerId,
							extension: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_voice_extension', voiceParams)
						})
						.then(response => {
							console.log('voice was dispatched');

							if (!--voiceIterations) {
								console.log("This is the last single voice iteration...");
								window.location.reload();
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				} else if ( Object.keys(params.customer_voice_extension).length == 0 && Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
					msValues.forEach(function(value, key) {
						const msParams = {
							customer_id: customerId,
							service_plan: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_microsoft_service_plan', msParams)
						})
						.then(response => {
							console.log('ms service plan was dispatched');

							if (!--msIterations) {
								console.log("This is the last single ms iteration...");
								window.location.reload();
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				}

			} else {
				console.log('no extensions or plans');
				window.location.reload();
			}
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function deleteCustomer(id) {
	console.log('the id is: ', id);
	console.log('the type is: ', (typeof id));
	return (dispatch, getState) => {
		return dispatch({
			type: DELETE_CUSTOMER,
			payload: axios.delete(BASEURL + '/records/customers/' + id)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function editCustomer(params, id) {
	console.log('the params are: ', params);
	// console.log('the type is: ', (typeof params));
	return (dispatch, getState) => {
		return dispatch({
			type: EDIT_CUSTOMER,
			payload: axios.put(BASEURL + '/records/customers/' + id, params.main)
		})
		.then(response => {
			console.log("response is: ", response);
			const customerId = response.value.data;

			// if both, if voice only, if microsoft only, else none
			if ( Object.keys(params.customer_voice_extension).length !== 0 || Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {

				const voiceValues = Object.values(params.customer_voice_extension);
				const msValues = Object.values(params.customer_microsoft_service_plan);
				let voiceIterations = voiceValues.length;
				let msIterations = msValues.length;

				if ( Object.keys(params.customer_voice_extension).length !== 0 && Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
					voiceValues.forEach(function(value, key) {
						const voiceParams = {
							customer_id: customerId,
							extension: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_voice_extension', voiceParams)
						})
						.then(response => {
							console.log('voice extension was dispatched');

							if (!--voiceIterations) {
								console.log("This is the last voice iteration...");

								if ( Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
									msValues.forEach(function(value, key) {
										const msParams = {
											customer_id: customerId,
											service_plan: value
										};

							        	dispatch({
											type: ADD_CUSTOMER,
											payload: axios.post(BASEURL + '/records/customer_microsoft_service_plan', msParams)
										})
										.then(response => {
											console.log('microsoft service plan was dispatched');

											if (!--msIterations) {
												console.log("This is the last ms iteration for both...");
												window.location.reload();
											}
										})
										.catch((error) => {
											if (error instanceof Error) {
												console.log(error);
											}
										});
							        });
								} else {
									window.location.reload();
								}
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				} else if ( Object.keys(params.customer_voice_extension).length !== 0 && Object.keys(params.customer_microsoft_service_plan).length == 0 ) {
					voiceValues.forEach(function(value, key) {
						const voiceParams = {
							customer_id: customerId,
							extension: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_voice_extension', voiceParams)
						})
						.then(response => {
							console.log('voice was dispatched');

							if (!--voiceIterations) {
								console.log("This is the last single voice iteration...");
								window.location.reload();
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				} else if ( Object.keys(params.customer_voice_extension).length == 0 && Object.keys(params.customer_microsoft_service_plan).length !== 0 ) {
					msValues.forEach(function(value, key) {
						const msParams = {
							customer_id: customerId,
							service_plan: value
						};

			        	dispatch({
							type: ADD_CUSTOMER,
							payload: axios.post(BASEURL + '/records/customer_microsoft_service_plan', msParams)
						})
						.then(response => {
							console.log('ms service plan was dispatched');

							if (!--msIterations) {
								console.log("This is the last single ms iteration...");
								window.location.reload();
							}
						})
						.catch((error) => {
							if (error instanceof Error) {
								console.log(error);
							}
						});
			        });
				}

			} else {
				console.log('no extensions or plans');
				window.location.reload();
			}
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function deleteExtension(id) {
	console.log('the id is: ', id);
	console.log('the type is: ', (typeof id));
	return (dispatch, getState) => {
		return dispatch({
			type: DELETE_EXTENSION,
			payload: axios.delete(BASEURL + '/records/customer_voice_extension/' + id)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function deleteServicePlan(id) {
	console.log('the id is: ', id);
	console.log('the type is: ', (typeof id));
	return (dispatch, getState) => {
		return dispatch({
			type: DELETE_SERVICE_PLAN,
			payload: axios.delete(BASEURL + '/records/customer_microsoft_service_plan/' + id)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}
