import axios from 'axios';
import {
	BASEURL,
	GET_ALL_BUILDINGS,
	ADD_BUILDING,
	DELETE_BUILDING,
	EDIT_BUILDING,
	CISCO_POWER
} from '../constants'


// const api = axios.create({
// 	headers: {
// 		Authorization: "Authorization"
// 	}
// });

export function getAllBuildings() {
	return (dispatch, getState) => {
		return dispatch({
			type: GET_ALL_BUILDINGS,
			payload: axios.get(BASEURL + '/records/buildings')
		})
		.then(response => {
			// console.log("getAllBuildings response is: ", response);
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function addBuilding(params) {
	// console.log('the params are: ', params);
	// console.log('the type is: ', (typeof params));
	return (dispatch, getState) => {
		return dispatch({
			type: ADD_BUILDING,
			payload: axios.post(BASEURL + '/records/buildings', params),
			body: params
		})
		.then(response => {
			// console.log("response is: ", response);
			// this.getAllBuildings();
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function deleteBuilding(id) {
	// console.log('the id is: ', id);
	// console.log('the type is: ', (typeof id));
	return (dispatch, getState) => {
		return dispatch({
			type: DELETE_BUILDING,
			payload: axios.delete(BASEURL + '/records/buildings/' + id)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function editBuilding(params, id) {
	console.log('the params are: ', params);
	// console.log('the type is: ', (typeof params));
	return (dispatch, getState) => {
		return dispatch({
			type: EDIT_BUILDING,
			payload: axios.put(BASEURL + '/records/buildings/' + id, params)
		})
		.then(response => {
			// console.log("response is: ", response);
			window.location.reload();
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}

export function ciscoPowerSwitch(power, cisco_ip, cisco_username, cisco_password, customer_port) {
	return (dispatch, getState) => {
		return dispatch({
			type: CISCO_POWER,
			// payload: axios.get( 'http://localhost/phpseclib/go.php', {params: {
			payload: axios.get( 'https://portal.johnlander.me/phpseclib/go.php', {params: {
				power: power,
				cisco_ip: cisco_ip,
				cisco_username: cisco_username,
				cisco_password: cisco_password,
				customer_port: customer_port
			}} )
		})
		.then(response => {
			console.log("ciscoPowerSwitch response is: ", response);
		})
		.catch((error) => {
			if (error instanceof Error) {
				console.log(error);
			}
		});
	}
}
